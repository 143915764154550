import loadsave from '@/builder/data/loadsave';
import pageUtils from '@pages/builder/canvas/pageUtils';
import legacyStyleUtils from '@pages/builder/legacy/legacyStyleUtils';
import Vue from 'vue';

const getNavigationPages = navigation => {
  const pages = [];
  navigation.forEach(page => {
    pages.push(page.data.id);
    page.children.forEach(child => {
      pages.push(child.data.id);
    });
  });
  return pages;
};

const getNavigationObject = id => ({
  data: {
    id
  },
  children: []
});

const getMegamenuHideScreens = parentPage => {
  const megamenuScreens = [
    parentPage.shouldHideMegamenuDesks ? 'desks' : false,
    parentPage.shouldHideMegamenuLaps ? 'laps' : false,
    parentPage.shouldHideMegamenuHtabs ? 'htabs' : false,
    parentPage.shouldHideMegamenuVtabs ? 'vtabs' : false,
    parentPage.shouldHideMegamenuMobiles ? 'mobiles' : false
  ];
  return megamenuScreens.filter(screen => !!screen);
};

const initializeNavigation = navigation => {
  // empty arrays get removed when saved in database, need to be readded for pages menu to work correctly
  navigation.forEach(page => {
    page.children = page.children || [];
    initializeNavigation(page.children);
  });
};

const flattenNavigation = (navigation, nesting) => {
  const flattened = JSON.parse(JSON.stringify(navigation));
  navigation.forEach(page => {
    page.children = flattenNavigation(page.children, nesting - 1);
    if (nesting > 0) return;
    page.children.forEach(child => {
      flattened.push(child);
    });
  });
  flattened.forEach(page => {
    page.children = [];
  });
  if (nesting > 0) return navigation;
  return flattened;
};

const stringSettings = [
  'favicon',
  'custom_css',
  'custom_html_head',
  'custom_html_body_top',
  'custom_html_body_bottom',
  'og_image',
  'og_title',
  'og_description',
  'fontFamily',
  'subdomain',
  'selectedDomain',
  'publish_custom_sub_domain',
  'publish_folder',
  'redirectType',
  'externalRedirect',
  'internalRedirect',
  'cookieAlertType',
  'cookieTitle',
  'cookieDescription',
  'cookieCTAlabel',
  'preferred_url'
  // 'publishType'
];

const arraySettings = [
  'primaryNavigation',
  'hiddenNavigation',
  'popups',
  'webinars',
  'funnels'
];

const boolSettings = [
  'isNewBuilderVersion',
  'shouldRedirect',
  'showCookieAlert',
  'removeRobotsTxt'
];
const objectSettings = ['userStyles', 'siteSettingsCopy'];

const mapBuilderSettings = (state, settings = {}) => {
  stringSettings.forEach(setting => {
    if (!state[setting] && typeof settings[setting] === 'undefined')
      state[setting] = '';
    else if (typeof settings[setting] !== 'undefined')
      state[setting] = settings[setting];
  });
  arraySettings.forEach(setting => {
    if (!state[setting] && !settings[setting]) state[setting] = [];
    else if (settings[setting]) state[setting] = settings[setting];
  });
  objectSettings.forEach(setting => {
    if (!state[setting] && !settings[setting]) state[setting] = {};
    else if (settings[setting]) state[setting] = settings[setting];
  });
  boolSettings.forEach(setting => {
    if (!state[setting] && !settings[setting]) state[setting] = false;
    else if (settings[setting] !== undefined)
      state[setting] = JSON.parse(settings[setting]);
  });
  return state;
};

export default {
  state: mapBuilderSettings({
    settings: {}
  }),
  mutations: {
    async mergeBuilderSettings(state, data) {
      await Object.keys(data || {}).forEach(setting => {
        state[setting] = data[setting];
      });
    },
    setSiteData(state, data) {
      state.settings = data;
      if (!data.builder_settings) return;
      state = mapBuilderSettings(state, data.builder_settings);
      initializeNavigation(state.primaryNavigation);
      initializeNavigation(state.hiddenNavigation);
      delete state.settings.builder_settings;
    },
    updateSiteSettings(state, data) {
      if (data.name) state.settings.name = data.name;
      if (!data.disableReset) state = mapBuilderSettings(state, data);
    },
    updateSiteData(state, data) {
      state.settings = data;
    },
    setShareLinks(state, data) {
      state.settings.share_link = data.share_link;
      state.settings.disabled_share_links = data.disabled_share_links;
    },
    setPrimaryNavigation(state, navigation) {
      state.primaryNavigation = navigation;
    },
    setHiddenNavigation(state, navigation) {
      state.hiddenNavigation = navigation;
    },
    setIsNewBuilderVersion(state, isNewBuilderVersion) {
      state.settings.isNewBuilderVersion = isNewBuilderVersion;
    },
    setPublishType(state, publishType) {
      state.publishType = publishType;
    },
    setNavigation(state, navigation) {
      state.primaryNavigation = flattenNavigation(
        navigation.primaryNavigation,
        1
      );
      state.hiddenNavigation = flattenNavigation(
        navigation.hiddenNavigation,
        0
      );
    },
    addToPrimaryNavigation(state, id) {
      const extractIds = ({ children, data }) => [
        data.id,
        ...children.reduce((nodes, node) => [...nodes, ...extractIds(node)], [])
      ];

      const existing = state.primaryNavigation.reduce(
        (navs, node) => [...navs, ...extractIds(node)],
        []
      );

      if (!existing.includes(id)) {
        const navigationData = getNavigationObject(id);
        state.primaryNavigation.push(navigationData);
      }
    },
    removeFromPrimaryNavigation(state, id) {
      const { primaryNavigation } = state;
      const index = primaryNavigation.findIndex(({ data }) => data.id === id);
      if (index > -1) {
        primaryNavigation.splice(index, 1);
      }
      state.primaryNavigation = primaryNavigation;
    },
    addToHiddenNavigation(state, id) {
      const navigationData = getNavigationObject(id);
      state.hiddenNavigation.push(navigationData);
    },
    deleteUserStyle(state, style) {
      Vue.delete(state.userStyles, style.id);
    },
    saveUserStyle(state, style) {
      Vue.set(state.userStyles, style.id, style);
    },
    setSiteSettingsCopy(state, val) {
      state.siteSettingsCopy = val;
    }
  },
  actions: {
    /* eslint-disable-next-line object-curly-newline */
    cleanNavigation({ getters, commit, state, rootState, rootGetters }) {
      // remove deleted page, and fix broken objects from buggy versions of the code
      const hiddenNavigation = [];
      state.hiddenNavigation.forEach(page => {
        const instance = rootState.pages.pages[page.data.id];
        if (
          getters.visiblePages.indexOf(page.data.id) === -1 &&
          rootState.pages.pages[page.data.id] &&
          !['funnel', 'webinar', 'popup', 'megamenu'].includes(instance.type)
        ) {
          hiddenNavigation.push(page);
        }
      });
      const primaryNavigation = [];
      state.primaryNavigation.forEach(page => {
        const children = [];
        const instance = rootState.pages.pages[page.data.id];
        page.children.forEach(child => {
          if (rootState.pages.pages[child.data.id] && instance) {
            children.push(child);
          } else if (rootState.pages.pages[child.data.id]) {
            primaryNavigation.push(child);
          }
        });
        page.children = children;
        if (instance && (!instance.type || ['page'].includes(instance.type))) {
          primaryNavigation.push(page);
        }
      });
      Object.keys(rootState.pages.pages || {}).forEach(pageId => {
        if (
          !getNavigationPages(primaryNavigation).includes(pageId) &&
          !getNavigationPages(hiddenNavigation).includes(pageId) &&
          !rootGetters['specialPages/groupPageIds'].includes(pageId) &&
          !rootGetters['megamenu/megamenuPages'].includes(pageId)
        ) {
          primaryNavigation.push(getNavigationObject(pageId));
        }
      });
      commit('setNavigation', { primaryNavigation, hiddenNavigation });
    },
    setNavigation({ commit, dispatch }, navigation) {
      commit('setNavigation', navigation);
      commit('setModified', true);
      dispatch('cleanNavigation');
    },
    updateSiteSettings({ commit }, data) {
      commit('setModified', true);
      commit('updateSiteSettings', data);
    },
    updateSiteData({ commit }, { data, silent }) {
      if (!silent) commit('setModified', true);
      commit('updateSiteData', data);
    },
    deleteUserStyle({ commit }, style) {
      commit('setModified', true);
      commit('deleteUserStyle', style);
    },
    saveUserStyle({ commit, rootState }, style) {
      commit('setModified', true);
      commit('saveUserStyle', style);
      rootState.pages.activePage.refreshGlobalStyles();
    },
    async pollSiteData({ commit, dispatch }) {
      const site = await loadsave.loadSiteData();
      commit('setSiteData', site);
      if ((site.pages_to_migrate || []).length) {
        setTimeout(() => dispatch('pollSiteData'), 2000);
      } else {
        window.location.reload();
      }
    }
  },
  getters: {
    hiddenPages: state => getNavigationPages(state.hiddenNavigation),
    visiblePages: state => getNavigationPages(state.primaryNavigation),
    siteSettings: (state, getters, rootState) => {
      const { settings } = state;
      settings.builder_settings = {};
      settings.builder_settings.pageCount = Object.keys(
        rootState.pages.pages || {}
      ).length;
      [
        ...stringSettings,
        ...arraySettings,
        ...objectSettings,
        ...boolSettings
      ].forEach(setting => {
        settings.builder_settings[setting] = state[setting];
      });
      return settings;
    },
    groupSlugs: (state, getters) => {
      const { builder_settings: builderSettings } = getters.siteSettings;
      const funnelSlugs =
        builderSettings?.funnels.reduce((set, funnel) => {
          if (funnel.useSlug && JSON.parse(funnel.useSlug))
            set[funnel.id] = funnel.slug;
          return set;
        }, {}) || {};
      const webinarSlugs =
        builderSettings?.webinars.reduce((set, webinar) => {
          if (webinar.useSlug && JSON.parse(webinar.useSlug))
            set[webinar.id] = webinar.slug;
          return set;
        }, {}) || {};
      return { funnels: funnelSlugs, webinars: webinarSlugs };
    },
    onPageNavigation: (state, getters, rootState) => {
      const navigation = [];
      state.primaryNavigation.forEach(page => {
        const parentPage = rootState.pages.pages[page.data.id];
        if (!parentPage) return;
        const parentPageMegamenuWidth = parentPage.megamenuMaxWidth || 600;
        const navigationData = {
          url: `${parentPage.settings?.name || 'Page'}`,
          name: parentPage.displayName,
          megamenu:
            parentPage.megamenu && !parentPage.shouldHideMegamenu
              ? rootState.pages.pages[parentPage.megamenu]?.settings?.id
              : null,
          megamenuHideScreens: getMegamenuHideScreens(parentPage),
          megamenuAlignment:
            parentPage.megamenuWidthType === 'static'
              ? parentPage.megamenuAlignment
              : null,
          megamenuMaxWidth:
            parentPage.megamenuWidthType === 'static'
              ? parentPageMegamenuWidth
              : null,
          children: []
        };
        page.children.forEach(child => {
          const childPage = rootState.pages.pages[child.data.id];
          if (!childPage) return;
          navigationData.children.push({
            url: `${childPage.settings.name}`,
            name: childPage.displayName
          });
        });
        navigation.push(navigationData);
      });
      return navigation;
    },
    globalStyleSheet: (state, getters, rootState) => {
      let sheet = '';
      if (rootState.pages.pageLoading) return '';

      Object.keys(state.userStyles || {}).forEach(style => {
        if( style == "undefined" )
          return;

        let styles = JSON.parse(state.userStyles[style].styles);
        if (!styles.all) {
          styles = legacyStyleUtils.convertLegacyGlobalStyle(
            rootState.pages.activePage,
            state.userStyles[style]
          );
        }
        sheet += pageUtils.getStylesheetForGlobals(style, styles);
      });
      return sheet;
    }
  }
};
